import { mapActions, mapState } from "vuex";
import { showError } from "@/helpers/globalHelpers";
export default {
  name: "ListPlanType",
  components: {
  },
  data() {
    return {
      isLoadingPlanTypes: false,
      planTypeFilters: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
        pagination: true,
      },
      planTypeHeaders: [
        { text: "Tipo de plan", sortable: false, value: "type_name" },
        { text: "Nombre de criterio", sortable: false, value: "criteria_name" },
        { text: "Permitir nombre plan de acción", sortable: false, value: "flag_action_plan_name" },
        { text: "Selección de responsables", sortable: false, value: "flag_responsibles" },
        { text: "Selección de servicio", sortable: false, value: "flag_service_selection" },
        { text: "Selección de usuarios a notificar", sortable: false, value: "flag_notify_users" },
        { text: "Selección de multiples fechas", sortable: false, value: "flag_multiple_dates" },
        { text: "Acciones", value: "actions", sortable: false },
      ]
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Tipos de plan de acción",
          link: true,
          exact: true,
          disabled: true,
          to: {
            name: "ListPlanType",
          },
        },
      ];
    },
    ...mapState("plan_type", ["planTypesPagination", "planTypes"]),

    filteredPlanTypes() {
      if (!this.planTypes || !Array.isArray(this.planTypes)) {
        return [];
      }
      return this.planTypes.filter(planType => {
        return (
          planType.type_name !== "SATISFACCION_CLIENTE" &&
          planType.type_name !== "CLIMA_LABORAL"
        );
      });
    }
  },
  created() {
    this.getPlanTypes();
  },
  methods: {
    ...mapActions('plan_type', ['listActionPlanPagination']),

    async getPlanTypes() {
      this.isLoadingPlanTypes = true;
      const { error } = await this.listActionPlanPagination(this.planTypeFilters)
      if (error) showError(error);
      this.isLoadingPlanTypes = false;
    },

    viewDetails(plan) {
      this.$router.push({ name: "DetailPlanType", params: { planTypeId: plan.id } });
    },
    editPlanType(plan) {
      this.$router.push({ name: "UpdatePlanType", params: { planTypeId: plan.id } });
    },
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
};